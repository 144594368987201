import React, {useState, useRef, useEffect} from "react"
import {Link, graphql} from "gatsby"
import styled from "styled-components";
import Layout from "../components/layout"
import SEO from "../components/seo"
import COLORS from "../styles/colors";
import KidsImage from "./../images/105.jpg";

const NewsHeadingWrapper = styled.div`
    background-color: ${COLORS.primaryColor};
    color: #fff;
    background: linear-gradient(
      rgba(53, 110, 216, 0.8), 
      rgba(53, 110, 216, 0.8)
    ),
    url(${KidsImage});
    background-position: center 13%;
    background-size: cover;
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const NewsHeadingContainer = styled.div`
    max-width: 640px;
    padding: 0 1rem;
    h1 {
        margin-top: 0;
        padding-top: 2rem;
    }
`;

const NewsHeadingDesc = styled.p`
    font-weight: 300;
`;

const InnerContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 600px;
    gap: 1rem;
    max-width: 1400px;
    margin: 0 auto;
    padding: 2rem;
    @media screen and (max-width: 1200px) {
        grid-template-columns: 1fr 500px;
    }
    @media screen and (max-width: 800px){
        grid-template-columns: 1fr;
    }
`;

const BlogItem = styled.div`
    padding: 1rem 0;
    margin-bottom: 2rem;
    text-decoration: none;
    color: #222;

    &:visited {
        color: #222;
    }
`;

const BlogItemTitle = styled.h1`
    margin: 0;
    padding: 0;
`;

const BlogItemDate = styled.span`
    color: #777;
    font-size: 0.8rem;
`;

const Hyphen = styled.div`
    display: inline-block;
    margin: 0 0.5rem;
    transform: scale(3,1);
`;

const BlogItemExcerpt = styled.div`
    font-weight: 300;    
`;

const InstagramFeedContainer = styled.div`
    max-width: 2000px;
    margin: 0 auto;
    padding: 0 2rem 3rem;
    text-align: center;
`;
const InstagramContainer = styled.div`
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
`;

const InstagramImage = styled.div`
    width: calc(33% - 0.5rem);
    margin: 0.25rem;
    min-height: 125px;
    height: auto;
    background-image: url(${props => props.src});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }

    @media screen and (max-width: 1200px) {
        width: calc(50% - 0.5rem);
    }
    @media screen and (max-width: 700px) {
        min-height: 200px;
        width: calc(100% - 0.5rem);
    }
`;

const TwitterContainer = styled.div`
    text-align: center;
`;

const Modal = styled.div`
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 300; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */

    &.active {
        display: block;
    }
`;

/* Modal Content/Box */
const ModalContent = styled.div`
    display: block;
    background-color: #fefefe;
    margin: 5% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */

    img {
        display: block;
        width: 100%;
        height: auto;
    }

    /* The Close Button */
    .close {
        display: flex;
        align-items: center;
        color: #111;
        float: right;
        font-size: 2rem;
        font-weight: bold;
        border: 2px dashed #ccc;
        padding: 0.5rem;
        &:hover {
            color: #000;
            text-decoration: none;
            border: 2px solid #ccc;
            cursor: pointer;
        }

        & > span {
            font-size: 1rem;
            display: inline-block;
            padding-right: 0.5rem;
        }
    }
`;

const News = ({data}) => {
    const [showModal, toggleShow] = useState(false);
    const [modalImage, setModalImage] = useState("");
    const ref = useRef();

    useEffect(() => {
        const listener = event => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) return;

            toggleShow(false);
        };

        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref]);

    return (
        <Layout>
            <SEO title="News" />
            <Modal className={showModal ? "active" : ""}>
                <ModalContent ref={ref}>
                    <span tabIndex={0} role="button" className="close" onClick={() => toggleShow(false)} onKeyDown={() => toggleShow(false)}><span>Close</span> &times;</span>
                    <img src={modalImage} alt="" />
                </ModalContent>
            </Modal>
            <NewsHeadingWrapper>
                <NewsHeadingContainer>
                    <h1>Latest News</h1>
                    <NewsHeadingDesc>Stay up to date on the newest theory, tips, and techniques applied in our workshops. Please follow us on Social Media for even more news!</NewsHeadingDesc>
                </NewsHeadingContainer>
            </NewsHeadingWrapper>
            <InnerContainer>
                <div>
                    {/* {data.allMarkdownRemark.edges.map(({node}) => (
                        <BlogItem key={node.id}>
                            <BlogItemTitle>{node.frontmatter.title}</BlogItemTitle>
                            <BlogItemExcerpt>
                                <BlogItemDate>{node.frontmatter.date}  <Hyphen>-</Hyphen></BlogItemDate>
                                {node.excerpt}</BlogItemExcerpt>
                            <Link to={node.fields.slug}>Read More...</Link>
                        </BlogItem>
                    ))} */}
                </div>
                <div>
                    <InstagramFeedContainer>
                        <h1>Instagram <a href="https://www.instagram.com/s3strategies/" target="_blank" rel="noopener noreferrer" style={{textDecoration: "none"}}>@s3strategies</a></h1>
                        <InstagramContainer>
                            {/* {data.allInstaNode.edges.map(({node}) => (
                                <InstagramImage role="button" key={node.id} src={node.preview} alt={node.caption} onClick={() => {
                                    toggleShow(true);
                                    setModalImage(node.preview);
                                }}
                                onKeyDown={() => {
                                    toggleShow(true);
                                    setModalImage(node.preview);
                                }} />
                            ))} */}
                        </InstagramContainer>
                    </InstagramFeedContainer>
                    <TwitterContainer>
                        <h1>Twitter <a href="https://twitter.com/s3strategies" target="_blank" rel="noopener noreferrer" style={{textDecoration: "none"}}>@s3strategies</a></h1>
                        <a className="twitter-timeline" data-height="1000" href="https://twitter.com/s3strategies">Loading...</a><script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script>
                    </TwitterContainer>
                </div>

            </InnerContainer>
           
        </Layout>
    )
}

export default News


